import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import * as auth from 'src/services/auth/auth.api';
import { getErrorMessage } from 'src/utils/error-handler';
import { createCtx } from 'src/utils/context';
import { WithChildrenProps } from 'src/utils/react';
import { Ability } from 'src/contexts/AbilityContext/ability';
import * as jose from 'jose';
import { ChangePasswordRequest } from 'src/services/auth/model/changePasswordRequest';
import { NameI18n } from '../services/iam/profiles/model/profileResponse';
import { RecoverPasswordRequest } from '../services/auth/model/recoverPasswordRequest';
import { ResetPasswordRequest } from '../services/auth/model/resetPasswordRequest';

export enum AuthAction {
  LOGIN_OK,
  OTP_REQUIRED,
  OTP_OK,
  UNAUTHORIZED,
  ERROR,
  RESET_PASSWORD_OK,
}

interface AuthResponse<T> {
  type: AuthAction;
  error?: string;
  data?: T;
}

interface ProfileWithAbilities {
  id: string;
  nameI18n: NameI18n;
  abilities: Ability[];
}

interface Unit {
  id: string;
  name: string;
}

interface Profile {
  id: string;
  nameI18n: NameI18n;
  abilities: Ability[];
  unit: Unit;
}

interface UnitWithProfiles {
  id: string;
  name: string;
  profiles: ProfileWithAbilities[];
}

interface User {
  email: string;
  unit: string;
  authToken?: string;
  profile?: Profile;
  features: string[];
  unitsProfiles?: UnitWithProfiles[];
  isTwoFactorAuthenticationEnabled?: boolean;
  isSecondFactorAuthenticated?: boolean;
}

const login = async (
  email: string,
  password: string,
  t: (key: string) => string,
  setUser: React.Dispatch<React.SetStateAction<User>>,
): Promise<AuthResponse<User>> => {
  try {
    const response = await auth.login(email, password);
    const token = jose.decodeJwt(response.access_token);
    const user: User = {
      email,
      unit: token.unit as string,
      authToken: response.access_token,
      isTwoFactorAuthenticationEnabled:
        response.isTwoFactorAuthenticationEnabled,
      isSecondFactorAuthenticated: false,
      unitsProfiles: token.unitsProfiles as UnitWithProfiles[],
      features:
        token.features === null || token.features === undefined
          ? []
          : (token.features as string[]),
    };

    if (user.unitsProfiles) {
      const unitProfile = user.unitsProfiles.find((up) => up.id === user.unit);
      if (unitProfile) {
        user.profile = {
          id: unitProfile.profiles[0].id,
          nameI18n: unitProfile.profiles[0].nameI18n,
          abilities: unitProfile.profiles[0].abilities,
          unit: { id: unitProfile.id, name: unitProfile.name },
        };
      }
    }

    localStorage.setItem('user', JSON.stringify(user));
    setUser(user);
    return {
      type: user.isTwoFactorAuthenticationEnabled
        ? AuthAction.OTP_REQUIRED
        : AuthAction.LOGIN_OK,
      data: user,
    };
  } catch (e) {
    if (e instanceof AxiosError) {
      const statusCode = e.response?.status;
      if (statusCode === 404 || statusCode === 500) {
        return { type: AuthAction.ERROR, error: t('server error') };
      } else if (statusCode === 401) {
        return {
          type: AuthAction.UNAUTHORIZED,
          error: t('user or password invalid'),
        };
      }
    }
    return { type: AuthAction.ERROR, error: getErrorMessage(e) };
  }
};

const verifyOTP = async (
  token: string,
  user: User,
  setUser: React.Dispatch<React.SetStateAction<User>>,
  t: (key: string) => string,
): Promise<AuthResponse<User>> => {
  try {
    const response = await auth.validateOTP(token, user.authToken!);
    user.isSecondFactorAuthenticated = true;
    user.authToken = response.access_token;
    localStorage.setItem('user', JSON.stringify(user));
    setUser(user);
    return { type: AuthAction.OTP_OK, data: user };
  } catch (e) {
    if (e instanceof AxiosError) {
      const statusCode = e.response?.status;
      if (statusCode === 401) {
        return { type: AuthAction.UNAUTHORIZED, error: t('otp token error') };
      }
    }
    return { type: AuthAction.ERROR, error: getErrorMessage(e) };
  }
};

const logout = (setUser: React.Dispatch<React.SetStateAction<User>>) => {
  localStorage.removeItem('user');
  setUser(null as any);
};

const changePassword = async (user: User, request: ChangePasswordRequest) => {
  await auth.changePassword(request, user.authToken!);
};

const setProfile = (
  id: string,
  unit: string,
  user: User,
  setUser: React.Dispatch<React.SetStateAction<User>>,
) => {
  const unitProfile = user.unitsProfiles?.find((up) => up.id === unit);
  if (!unitProfile) {
    throw new Error('Unit not found');
  }

  const profile = unitProfile.profiles.find((p) => p.id === id);
  if (!profile) {
    throw new Error('Profile not found');
  }

  user.profile = {
    id: profile.id,
    nameI18n: profile.nameI18n,
    abilities: profile.abilities,
    unit: { id: unitProfile.id, name: unitProfile.name },
  };
  localStorage.setItem('user', JSON.stringify(user));
  setUser({ ...user });
};

const recoverPassword = async (
  request: RecoverPasswordRequest,
): Promise<AuthResponse<void>> => {
  try {
    await auth.recoverPassword(request);
    return { type: AuthAction.RESET_PASSWORD_OK };
  } catch (e) {
    return { type: AuthAction.ERROR, error: getErrorMessage(e) };
  }
};

const resetPassword = async (
  request: ResetPasswordRequest,
): Promise<AuthResponse<void>> => {
  try {
    await auth.resetPassword(request);
    return { type: AuthAction.RESET_PASSWORD_OK };
  } catch (e) {
    if (e instanceof AxiosError) {
      const statusCode = e.response?.status;
      if (statusCode === 404) {
        return { type: AuthAction.UNAUTHORIZED, error: 'user_not_found' };
      }
    }
    return { type: AuthAction.ERROR, error: getErrorMessage(e) };
  }
};

interface AuthContextType {
  user: User;
  login: (email: string, password: string) => Promise<AuthResponse<User>>;
  verifyOTP: (token: string) => Promise<AuthResponse<User>>;
  logout: () => void;
  changePassword: (request: ChangePasswordRequest) => Promise<void>;
  setProfile: (id: string, unit: string) => void;
  recoverPassword: (
    request: RecoverPasswordRequest,
  ) => Promise<AuthResponse<void>>;
  resetPassword: (request: ResetPasswordRequest) => Promise<AuthResponse<void>>;
}

const [AuthContext, CurrentAuthContextProvider] = createCtx<AuthContextType>();

export const AuthProvider = ({ children }: WithChildrenProps) => {
  const { t } = useTranslation();
  const localUser = localStorage.getItem('user');
  const [user, setUser] = useState<User>(
    localUser ? JSON.parse(localUser) : null,
  );
  const value = {
    user,
    login: (email: string, password: string) =>
      login(email, password, t, setUser),
    verifyOTP: (token: string) => verifyOTP(token, user, setUser, t),
    logout: () => logout(setUser),
    changePassword: (request: ChangePasswordRequest) =>
      changePassword(user, request),
    setProfile: (id: string, unit: string) =>
      setProfile(id, unit, user, setUser),
    recoverPassword: (request: RecoverPasswordRequest) =>
      recoverPassword(request),
    resetPassword: (request: ResetPasswordRequest) => resetPassword(request),
  };

  return (
    <CurrentAuthContextProvider value={value}>
      {children}
    </CurrentAuthContextProvider>
  );
};

export const useAuth = () => {
  return AuthContext();
};
